const middleware = {}

middleware['authGuard'] = require('../middleware/authGuard.js')
middleware['authGuard'] = middleware['authGuard'].default || middleware['authGuard']

middleware['cookiebot'] = require('../middleware/cookiebot.js')
middleware['cookiebot'] = middleware['cookiebot'].default || middleware['cookiebot']

middleware['gtm'] = require('../middleware/gtm.js')
middleware['gtm'] = middleware['gtm'].default || middleware['gtm']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

export default middleware
